import React from "react"
import PageTransitionLink from "../../UI/PageTransition/PageTransitionLink"

import "./Footer.scss"

const Footer = () => (
  <footer className="footer">
    <div className="data-visualisation p-2 pb-3 py-md-3">
      <span className="text-mask d-inline-block">#MF2019</span>
    </div>
    <nav className="footer-navigation">
      <ul className="m-0 p-0">
        <li className="d-none d-sm-block">&copy; 2019 METER Group, Inc. USA</li>
        <li>
          <PageTransitionLink to="/imprint">Imprint</PageTransitionLink>
        </li>
      </ul>
    </nav>
  </footer>
)

export default Footer
