import React from "react"
import Headroom from "react-headroom"
import { ReactComponent as LogoDesktop } from "Static/assets/images/meltdown-flags-desktop.svg"
import { ReactComponent as LogoMobile } from "Static/assets/images/meltdown-flags-mobile.svg"
import NavigationOverlay from "Sections/NavigationOverlay/NavigationOverlay.jsx"
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"
import MenuLinks from "UI/MenuLinks/MenuLinks.jsx"

import "./Header.scss"

const Header = () => {
  let pinOffset;

  if(typeof document !== `undefined`){
    const banner = document.getElementById('banner')
    pinOffset = banner ? banner.clientHeight: 0;
  }

  return (
    <>
      <Headroom pinStart={pinOffset} className="headroom">
        <header className="header d-flex justify-content-between pt-2 px-2">
          <div className="logo">
            <PageTransitionLink to="/">
              <LogoMobile className="d-md-none"  />
              <LogoDesktop className="d-none d-md-block" />
            </PageTransitionLink>
          </div>
          <MenuLinks />
          <NavigationOverlay />
        </header>
      </Headroom>
    </>
  )
}

export default Header
