/* eslint-disable no-undef */
import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import CookieConsent from "react-cookie-consent"
import Icon from "../Icon/Icon"

import "./CookieMessage.scss"

const CookieMessage = () => {
  const { site } = useStaticQuery(graphql`
    query CookieMessageQuery {
      site {
        siteMetadata {
          cookieMessage
        }
      }
    }
  `)

  const [cookieAccepted, setCookieAccepted] = useState("")

  return (
    <CookieConsent
      cookieName="MeltdownFlagsChocolateCookie"
      location="bottom"
      disableStyles
      disableButtonStyles
      enableDeclineButton
      flipButtons
      hideOnAccept={false}
      buttonText={<Icon iconType="check" />}
      declineButtonText={<Icon iconType="plus" rotation="45deg" />}
      declineButtonClasses="ml-2"
      containerClasses={classNames("cookie-message-wrapper d-flex justify-content-between px-2 px-lg-2 py-2 py-lg-2 bg-sand", cookieAccepted ? "is-hidden" : "")}
      contentClasses="p d-flex align-items-center mr-md-2 flex-grow-1"
      onAccept={() => setCookieAccepted(true)}
      onDecline={() => { setCookieAccepted(false); gaOptout()}}
    >
      <div dangerouslySetInnerHTML={{ __html: site.siteMetadata.cookieMessage }} />
    </CookieConsent>
  )
}

export default CookieMessage