import React, { useEffect } from "react";
// import classNames from "classnames";
import MenuLinks from "UI/MenuLinks/MenuLinks.jsx"
import ShapeOverlays from "./ShapeOverlays"

import "./NavigationOverlay.scss";


const NavigationOverlay = () => {

    // setTimeout(() => document.body.classList.add('render'), 60);
    // const navdemos = Array.from(document.querySelectorAll('nav.demos > .demo'));
    // const navigate = (linkEl) => {
    //   document.body.classList.remove('render');
    //   document.body.addEventListener('transitionend', () => {
    //     window.location = linkEl.href
    //   });
    // };
    // navdemos.forEach(link => link.addEventListener('click', (ev) => {
    //   ev.preventDefault();
    //   navigate(ev.target);
    // }));

  useEffect(() => {
    const elmHamburger = document.querySelector('.hamburger');
    const gNavItems = document.querySelectorAll('.global-menu__item');
    const elmOverlay = document.querySelector('.shape-overlays');
    const overlay = new ShapeOverlays(elmOverlay);

    elmHamburger.addEventListener('click', () => {
      if (overlay.isAnimating) {
        return false;
      }
      overlay.toggle();
      if (overlay.isOpened === true) {
        elmHamburger.classList.add('is-opened-navi');
        document.body.classList.add('menu-open');
        for (let i = 0; i < gNavItems.length; i+=1) {
          gNavItems[i].classList.add('is-opened');
        }
      } else {
        elmHamburger.classList.remove('is-opened-navi');
        document.body.classList.remove('menu-open');
        for (let i = 0; i < gNavItems.length; i+=1) {
          gNavItems[i].classList.remove('is-opened');
        }
      }
      return true;
    });

    return (
      () => {
        document.body.classList.remove('menu-open');
      }
    )
  })

  return (
    <>
      <div className="fluid-menu-overlay">
        <div className="hamburger js-hover">
          <div className="hamburger__line hamburger__line--01">
            <div className="hamburger__line-in hamburger__line-in--01" />
          </div>
          <div className="hamburger__line hamburger__line--02">
            <div className="hamburger__line-in hamburger__line-in--02" />
          </div>
          <div className="hamburger__line hamburger__line--03">
            <div className="hamburger__line-in hamburger__line-in--03" />
          </div>
          <div className="hamburger__line hamburger__line--cross01">
            <div className="hamburger__line-in hamburger__line-in--cross01" />
          </div>
          <div className="hamburger__line hamburger__line--cross02">
            <div className="hamburger__line-in hamburger__line-in--cross02" />
          </div>
        </div>

        <MenuLinks burgerMenu />
        <svg className="shape-overlays" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path className="shape-overlays__path" />
          <path className="shape-overlays__path" />
          <path className="shape-overlays__path" />
        </svg>
      </div>
    </>
  );
}

export default NavigationOverlay