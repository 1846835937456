import React from "react"
import PageTransitionLink from "UI/PageTransition/PageTransitionLink.jsx"
import classNames from "classnames"

const MenuLinks = ({burgerMenu}) => {
  return (
    <nav className={classNames("main-navigation", burgerMenu && "global-menu", !burgerMenu && "desktop-menu")}>
      <ul className={classNames("m-0 p-0", burgerMenu && "global-menu__wrap", !burgerMenu && "d-flex")}>
        <li className={classNames("text-link", burgerMenu && "global-menu__item")}><PageTransitionLink to="/#index">Flags</PageTransitionLink></li>
        <li className={classNames("text-link", burgerMenu && "global-menu__item")}><PageTransitionLink to="/about">About</PageTransitionLink></li>
        <li className={classNames("text-link", burgerMenu && "global-menu__item")}><PageTransitionLink to="/act">Act</PageTransitionLink></li>
      </ul>
    </nav>
  )
}

export default MenuLinks
