import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from "react-device-detect";
import classNames from "classnames"
import Header from "Sections/Header/Header.jsx"
import Footer from "Sections/Footer/Footer.jsx"
import CookieMessage from "UI/CookieMessage/CookieMessage.jsx"
import "../../../styles/styles.scss"
import "./Layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // console.log("Is Mobile Device: ", isMobile)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={classNames(isMobile && "is-mobile")}>{children}</main>
      <Footer />
      <CookieMessage />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout